:root {
  --primary-color: #9E2313;
  --secondary-color-text: #242424;
  --primary-color-text: #000000;
  --background-color: #FDFFFF;
  --inactive-color-1: #8F8F8F;
  --inactive-color-2: #969696;
  --light-background: #ddd;
}

.awssld__controls__arrow-right,
.awssld__controls__arrow-left {
  --organic-arrow-height: 17px;

}

.awssld__bullets button {
  width: 5px;
  height: 5px;
}

.news__Feed_container img {
  max-width: 100%;
  height: auto;
}

.news__Feed_card {
  margin-top: -31px;
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
}

.btn-light-o {
  background-color: #e2e6ea;
  border-color: #b3b6ba;
  color: #000000;
  font-weight: 800;
}

.page-item.disabled>.page-link {
  background-color: #dedede;
}

/**Customization */

.enlarged .left-side-menu #sidebar-menu ul.nav-second-level>li>a {
  padding: 15px 30px;
  min-height: 56px;
}

#sidebar-menu ul.nav-second-level>li>a {
  padding: 11px 20px 11px 20px;
}

#sidebar-menu ul.nav-second-level.third-level>li>a {
  padding: 11px 20px 11px 30px;
}

#sidebar-menu ul.nav-second-level>li>a>span {
  vertical-align: middle;
}

.enlarged .left-side-menu #sidebar-menu ul.nav-second-level>li>a span {
  display: none;
  padding-left: 25px;
}

#sidebar-menu ul.nav-second-level>li>a i {
  display: inline-block;
  line-height: 1.0625rem;
  margin: 0 10px 0 3px;
  text-align: center;
  vertical-align: middle;
  width: 20px;
  font-size: 1rem;
}

#sidebar-menu ul>li .nav-link.collapsed>.menu-arrow {
  --webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

#sidebar-menu ul>li .nav-link>.menu-arrow {
  --webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

#sidebar-menu li.mm-active>a>span.menu-arrow {

  --webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.scroll-menu {

  height: 100%;
  overflow-x: scroll;
  overflow-y: auto;
}

.scroll-menu::-webkit-scrollbar-track {
  padding: 2px 0;
  border-radius: 5px;
}

.scroll-menu::-webkit-scrollbar {
  width: 5px;
}

.scroll-menu::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(158, 165, 171, 0.2);
  background-color: rgba(158, 165, 171, 0.2);
  border: 1px solid rgba(158, 165, 171, 0.2);
}

.scroll-menu::-webkit-scrollbar-corner {
  background-color: transparent;
}

.app-h1 {
  font-size: 20px;
}
.app-p{
  margin-bottom: 0.5rem;
}